import * as React from 'react'
import { SP, PC } from '../components/utils/media-query'
import Header from '../components/organisms/Header'
import Footer from '../components/organisms/Footer'
import Button from '../components/atoms/TheButton'
import HeadingIllust from '../components/atoms/HeadingIlust'

const styles = require('scss/achievements.module.scss')
const pcHeadImage = require('images/product.svg')

const achievements = () => {
  const systems = [
    {
      img: require('images/erudinno2.png'),
      system: 'SaaS型タスク管理管理ツール Erudinno',
      text: `ポピュラーな「カンバン」や「ガントチャート」でのタスクの一元管理機能に加え「マネージャーがいなくても運用できる」ほど管理コストがかからない、自動化に重きをおいたサービスです。「スケジュール変更をツールに反映する工数がもったいない」「期限が過ぎたタスクが放置されている」「リモートワーク下で誰が成果を出しているのかが不明」「日々スケジュールが変わり振り返りが困難」など、タスクやプロジェクトの管理に関わる課題を解決します。`,
    },
    {
      img: require('images/shopping.svg'),
      system: 'ECサイト/新しい決済機能を追加',
      text: `日本国内のBtoC市場におけるEC市場規模は毎年右肩上がりで伸び続けており、ECサイトの設置でこれまでリーチできなかった顧客にアプローチできるようになり、コロナ禍でも売り上げが伸びている企業も多数！更にQRコード決済の利用率は2019年の12％から2021年に54%と大幅に拡大していることから、(インフキュリオン社が行った「決済動向2021年4月」より)既にECサイトを運営している方も、既存のECサイトに新しい決済機能を追加することで更なる売り上げ増進が見込めます。`,
    },
    {
      img: require('images/stock.svg'),
      system: 'オリジナル在庫管理システム',
      text: `数字の入力ミスや伝票処理漏洩れ、管理ミスによる棚卸差異に悩んでいませんか？システムで在庫を管理することによって最低限の手入力のみで入力ミスや漏れのない正確な在庫管理が可能に！更に別のシステムからデータを取り込んだり、データをCSV形式で吐き出したりと、他のシステムとの連携が可能なためストレスフリーに業務が進められます。簡単操作で在庫不足や過剰在庫を回避し、機会損失を被ることなく適切な管理を実現します。
      `,
    },
  ]
  const LP = [
    {
      img: require('images/LP1.svg'),
      text: `テンプレートを使用し制作させていただきます。なるべく早く、とにかく費用を安く自社サイトが欲しい！という方、また公開後の修正は自分でしたい、という方にもおすすめです。`,
      plan: 'エントリープラン',
      price: '¥100,000 ~',
    },
    {
      img: require('images/LP2.svg'),
      text: `オリジナルでデザインもこだわりWebからの集客を強化したい、またレスポンシブ対応、公開後の修正もお願いしたい！
        という方におすすめです。機能やデザインの追加/削除によって費用は調整可能です。`,
      plan: 'スタンダードプラン',
      price: '¥200,000 ~ ¥300,000',
    },
    {
      img: require('images/LP3.svg'),
      text: `構成・デザインはもちろん、ターゲットや競合の調査をした上で広告運用を考えているなど、これからWebマーケティングを本格的に取り組んでいきたい！という方におすすめです。`,
      plan: 'プレミアムプラン',
      price: '¥600,000 ~',
    },
  ]

  return (
    <section>
      <Header />
      <PC>
        <HeadingIllust
          title="開発実績"
          pageName="Achievements"
          image={pcHeadImage}
          top={10}
          width={280}
        />
      </PC>
      <SP>
        <HeadingIllust
          title="開発実績"
          pageName="Achievements"
          image={pcHeadImage}
          top={1}
          width={170}
        />
      </SP>
      <div className={styles.body}>
        {systems.map((item) => {
          return (
            <div className={styles.systemItems}>
              <div
                key={item.text}
                className={styles.samples}
              >
                <div className={styles.leftSide}>
                  <img
                    className={styles.sample}
                    src={item.img}
                  />
                </div>
                <div className={styles.rightSide}>
                  <p className={styles.system}>
                    {item.system}
                  </p>
                  <p className={styles.desc}>{item.text}</p>
                </div>
              </div>
            </div>
          )
        })}
        <div className={styles.button}>
          <PC>
            <Button
              type="square"
              icon
              to="https://nichicoma.co.jp/#contact"
            >
              ご相談はこちら
            </Button>
          </PC>
          <SP>
            <Button
              size="responsive"
              icon
              to="https://nichicoma.co.jp/#contact"
            >
              ご相談はこちら
            </Button>
          </SP>
        </div>
      </div>
      <div className={styles.lpBanner}>
        <h3 className={styles.lpTitle}>LP制作費用</h3>
        <p className={styles.lpText}>
          ニチコマでは、LPの制作ご依頼も承っております。
        </p>
      </div>

      <div className={styles.bottom}>
        {LP.map((item) => {
          return (
            <div className={styles.lpItems}>
              <div
                key={item.text}
                className={styles.samples}
              >
                <div className={styles.leftSide}>
                  <img
                    className={styles.sample}
                    src={item.img}
                  />
                </div>
                <div className={styles.rightSide}>
                  <p className={styles.system}>
                    {item.plan}
                  </p>
                  <p className={styles.desc}>{item.text}</p>
                  <p className={styles.price}>
                    {item.price}
                  </p>
                </div>
              </div>
            </div>
          )
        })}
        <div className={styles.button}>
          <PC>
            <Button
              type="square"
              elevation="1"
              icon
              to="https://nichicoma.co.jp/#contact"
            >
              ご相談はこちら
            </Button>
          </PC>
          <SP>
            <Button
              size="responsive"
              elevation="1"
              icon
              to="https://nichicoma.co.jp/#contact"
            >
              ご相談はこちら
            </Button>
          </SP>
        </div>
      </div>
      <Footer />
    </section>
  )
}

export default achievements
